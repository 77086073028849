// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-resources-casestudy-js": () => import("./../src/pages/resources/casestudy.js" /* webpackChunkName: "component---src-pages-resources-casestudy-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-dpa-js": () => import("./../src/pages/dpa.js" /* webpackChunkName: "component---src-pages-dpa-js" */),
  "component---src-pages-gdpr-js": () => import("./../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-js": () => import("./../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-trust-js": () => import("./../src/pages/trust.js" /* webpackChunkName: "component---src-pages-trust-js" */)
}

